import { createRouter, RouteRecordRaw, createWebHashHistory } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/screen1'
  },
  {
    path: '/screen1',
    name: 'screen1',
    component: () => import('@/views/screen1/IndexView.vue')
  },
  {
    path: '/screen2',
    name: 'screen2',
    component: () => import('@/views/screen2/IndexView.vue')
  },
  {
    path: '/screen3',
    name: 'screen3',
    component: () => import('@/views/screen3/IndexView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
