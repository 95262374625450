<template>
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans TC', sans-serif;
}
.hidden-scrollbar {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
}
.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}
</style>
