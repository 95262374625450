import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import setRootFontSize from './utils/adapter'

setRootFontSize()
window.addEventListener('resize', function () {
    setRootFontSize()
})

createApp(App)
    .use(router)
    .use(createPinia())
    .mount('#app')
