const baseDimensions = 1920
const baseRem = 10

function setRootFontSize(): number {
    const windowWidth = window.innerWidth
    const proportion = windowWidth / baseDimensions
    const rem = baseRem * proportion
    document.documentElement.style.fontSize = rem + 'px'
    return rem
}

export default setRootFontSize